//* Style
import ContentStyle from './style';

//* Components
import Editor from '@/components/Editor';
import Container from '@/components/Container';
import Image from '@/components/Image';

const Content = ({ image, description, description_2 }) => {
	return (
		<ContentStyle>
			<Container
				row
				full>
				<div className={'wrapper-content'}>
					<div className='wrapper-editor'>
						<Editor data={description} />
					</div>

					{image && image.src && (
						<div className='image-wrapper'>
							<Image
								src={image?.src}
								alt={image?.alt}
							/>
						</div>
					)}
					{description_2 && (
						<div className='wrapper-editors'>
							<Editor data={description_2} />
						</div>
					)}
				</div>
			</Container>
		</ContentStyle>
	);
};

export default Content;
