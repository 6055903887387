import styled from 'styled-components';

const ShareStyle = styled.div`
	--padRightShare: var(--sp3-5x);
	--distanceShare: var(--sp2x);
	--mediaShareSize: var(--sp5x);

	display: flex;
	flex-wrap: wrap;
	align-items: center;
	column-gap: var(--distanceShare);

	.link-item {
		transition: transform var(--trTime) ease-out;
		border-radius: var(--sp1x);
		padding: 6px 9px;
		background-color: var(--white);
	}

	.media-share {
		cursor: pointer;
	}

	@media (hover: hover) {
		.link-item {
			&:hover {
				transform: scale(1.2);
			}
		}
	}

	//! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		.link-item {
			padding: 1px 4px;
			i {
				font-size: var(--h5);
			}
		}
	}

	//! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
	}

	//! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
	}

	//! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
	}

	//! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		.link-item {
			i {
				font-size: var(--h2);
			}
		}
	}

	//! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
	}
`;

export default ShareStyle;
