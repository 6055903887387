import styled from 'styled-components';

const RelatedStyle = styled.section`
	--padTB: var(--sp6x);
	--padLR: var(--sp4x);
	--topBlocMarginB: var(--sp6x);
	--borderMarginTB: var(--sp4x);
	--borderRadius: var(--sp4x);
	--textMarginB: var(--sp4x);

	background-color: var(--white);
	border-radius: var(--borderRadius);
	padding: var(--padTB) var(--padLR);

	.related-top-block {
		margin-bottom: var(--topBlocMarginB);
	}

	.border-wrapper {
		border: 1px solid #eff1f2;
		margin: var(--borderMarginTB) 0;
	}

	.second-block .related-block:last-of-type .border-wrapper {
		display: none;
	}

	.text-wrapper {
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		margin-bottom: var(--textMarginB);
	}

	//! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--padTB: var(--sp5x);
		--padLR: var(--sp4x);
		--topBlocMarginB: var(--sp4x);
		--borderMarginTB: var(--sp3x);
	}

	//! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--topBlocMarginB: var(--sp3-5x);
		--borderMarginTB: var(--sp2x);
		--padTB: var(--sp4x);
		--padLR: var(--sp3x);
		--textMarginB: var(--sp3x);
	}

	//! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--borderRadius: var(--sp3x);
		--padTB: var(--sp3x);
		--padLR: var(--sp2-5x);
		--textMarginB: var(--sp2x);
	}

	//! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--borderRadius: var(--sp2-5x);
	}

	//! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--topBlocMarginB: var(--sp3x);
		--borderMarginTB: var(--sp1-5x);
		--borderRadius: var(--sp1-5x);
		--padTB: var(--sp2-5x);
		--padLR: var(--sp1-5x);
		--textMarginB: var(--sp1x);
	}

	//! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--borderRadius: var(--sp2x);
		--padTB: var(--sp2x);
		--textMarginB: var(--sp1-5x);
	}
`;

export default RelatedStyle;
