import styled from 'styled-components';

const ContentStyle = styled.section`
	--imgBorderRadius: var(--sp5x);
	--imgSize: 51.2%;
	--imgMarginTB: var(--sp3x);

	.image-wrapper .image-cont {
		--proportion: var(--imgSize);
		margin: var(--imgMarginTB) 0;

		img {
			border-radius: var(--imgBorderRadius);
		}
	}

	//! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--imgMarginTB: var(--sp5x);
		--imgBorderRadius: var(--sp4x);
	}

	//! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--imgMarginTB: var(--sp4x);
		--imgBorderRadius: var(--sp3x);
	}

	//! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--borderRadius: var(--sp3x);
		--imgBorderRadius: var(--sp2-5x);
	}

	/* //! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--borderRadius: var(--sp2-5x);
	}

	//! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--borderRadius: var(--sp1-5x);
		--imgSize: 65.2%;
		--imgMarginTB: var(--sp3x);
		--imgBorderRadius: var(--sp2x);
	}

	//! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--borderRadius: var(--sp2x);
		--imgSize: 49.2%;
		--imgMarginTB: var(--sp4x);
		--imgBorderRadius: var(--sp1-5x);
	}
`;

export default ContentStyle;
