import { useState } from 'react';

//* HOC's
import { withLanguageContext } from '@/context';

//* Style
import TabStyle from './style';

//* Components
import Text from '@/components/Text';

const Tab = ({ availableTabs, onTabSelect, defaultTabKey }) => {
	const [selectedTab, setSelectedTab] = useState(defaultTabKey);

	const handleTabClick = (tabKey) => {
		setSelectedTab(tabKey);
		onTabSelect(tabKey);
	};

	return (
		<TabStyle>
			<div className={'second-block'}>
				{availableTabs.map((tab) => (
					<div
						key={tab.key}
						className={'tab-block'}>
						<Text
							key={tab.key}
							onClick={() => handleTabClick(tab.key)}
							className={`text-wrapper p1 font-montserrat-arm-medium blue-1000 ${selectedTab === tab.key ? 'active' : ''}`}>
							{tab.label}
						</Text>

						<div className={'border-wrapper'}></div>
					</div>
				))}
			</div>
		</TabStyle>
	);
};

export default withLanguageContext(Tab, ['selectedLang', 'translate']);
