'use client';
import { useEffect, useRef, useState } from 'react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import gsap from 'gsap/all';

//* HOC's
import { withLanguageContext, withUIContext } from '@/context';

//* Helpers
import { config } from '@/helpers';

//* Components
import Text from '../../Text';
import Container from '../../Container';
import Image from '../../Image';
import BreadCrump from '../../BreadCrump';
import Share from '../../Share';
import Content from '../components/Content';
import Related from '../components/Related';
import ScrollActive from '@/components/ScrollActive';
import TabContent from '../components/TabContent';
import Tab from '../components/Tab';

//* Style
import IndividualTemplateStyle from './style';

const IndividualTemplate = ({ translate, pageType, pageData, winWidth }) => {
	const availableTabs = [pageData.about_description && { key: 'about', label: translate('aboutTheReform') }, pageData.problem_description && { key: 'problem', label: translate('problemAndCurrentSituation') }, pageData.change_description && { key: 'change', label: translate('proposedChange') }, pageData.mapped_description && { key: 'mapped', label: translate('byWhomMapped') }, pageData.agenda && { key: 'agenda', label: translate('agenda') }, pageData.report && (pageData.report.description || pageData.report.file) && { key: 'report', label: translate('reportText') }, pageData.minutes && (pageData.minutes.description || pageData.minutes.file) && { key: 'minutes', label: translate('minutes') }, pageData.video && (pageData.video.youtube_link || pageData.video.file) && { key: 'video', label: translate('video') }].filter(Boolean);

	const [selectedTab, setSelectedTab] = useState(availableTabs[0]?.key || 'agenda');

	const handleTabSelect = (tabKey) => {
		setSelectedTab(tabKey);
	};

	//! Ref
	const stickyWrapperRef = useRef();
	const triggerRef = useRef();
	const wrapperBottom = useRef();
	const wrapperLeft = useRef();
	const titleRef = useRef();
	const dateRef = useRef();
	const wrapperCategories = useRef();
	const bredCrumpRef = useRef();
	const imageRef = useRef();
	const shareBottomRef = useRef();
	const shareRef = useRef();

	//! Animation Loaded
	useEffect(() => {
		if (bredCrumpRef.current && shareRef.current) {
			gsap.to(bredCrumpRef.current, { opacity: 1, ease: 'power2.out', duration: 0.6, delay: 0.3 });

			gsap.to(shareRef.current, { opacity: 1, ease: 'power2.out', duration: 0.6, delay: 0.7 });

			gsap.to(shareBottomRef.current, { opacity: 1, ease: 'power2.out', duration: 0.6, delay: 0.7 });

			gsap.to(imageRef.current, { opacity: 1, duration: 0.6, ease: 'power2.out', delay: 0.7 });
			gsap.fromTo(imageRef.current, { y: '50%' }, { y: 0, ease: 'power2.out', duration: 0.8, delay: 0.7 });
		}
		if (wrapperCategories.current) {
			gsap.to(wrapperCategories.current, { opacity: 1, ease: 'power2.out', duration: 0.6, delay: 0.6 });
		}

		return () => {
			if (triggerRef.current) {
				triggerRef.current.kill();
				triggerRef.current = null;
			}
		};
	}, []);

	//! Sticky
	useEffect(() => {
		let observer;
		if (winWidth >= 768) {
			observer = new ResizeObserver((entries) => {
				for (let entry of entries) {
					const stickyHeight = entry.contentRect.height;

					if (stickyHeight > 0) {
						const padTop = parseInt(window.getComputedStyle(wrapperBottom.current).paddingTop);

						if (triggerRef.current) {
							triggerRef.current.kill();
						}

						triggerRef.current = ScrollTrigger.create({
							trigger: stickyWrapperRef.current,
							scrub: true,
							start: `top top+=${padTop}`,
							end: `bottom-=${stickyHeight} top+=${padTop}`,
							endTrigger: wrapperLeft.current,
							pin: stickyWrapperRef.current,
							pinSpacing: false,
							// markers: true,
						});
					}
				}
			});

			if (stickyWrapperRef.current && observer) {
				observer.observe(stickyWrapperRef.current);
			}
		}

		return () => {
			if (triggerRef.current) {
				triggerRef.current.kill();
			}
			if (observer) {
				observer.disconnect();
			}
		};
	}, [winWidth]);

	return (
		<IndividualTemplateStyle>
			<Container isSection>
				<div className='wrapper-top row'>
					<div
						ref={bredCrumpRef}
						className='col-12 opacity-0'>
						<BreadCrump
							url={pageType === 'meetings' ? config.routes.councilMeetings.path : config.routes[pageType]?.path}
							text={`${translate(pageType)}`}
						/>
					</div>
				</div>

				<div className='wrapper-middle row'>
					<div className='col-4 col-t-12'>
						<div className='wrapper-top-content'>
							<div className={'wrapper-top-content-data'}>
								{pageType !== 'reforms' && (
									<ScrollActive
										animTitle
										overflow={true}
										allRefs={dateRef}>
										<Text
											ref={dateRef}
											className={'p1 font-montserrat-arm-medium date-text opacity-0'}>
											{pageData.date}
										</Text>
									</ScrollActive>
								)}
								{pageType === 'reforms' && pageData.year && (
									<ScrollActive
										animTitle
										overflow={true}
										allRefs={dateRef}>
										<Text
											ref={dateRef}
											className={'p1 font-montserrat-arm-medium date-text opacity-0'}>
											{pageData.year}
										</Text>
									</ScrollActive>
								)}

								<ScrollActive
									animTitle
									overflow={true}
									allRefs={titleRef}>
									<Text
										tag={'h1'}
										ref={titleRef}
										className={'h4 font-montserrat-arm-medium blue-1000 title-wrapper opacity-0'}>
										{pageData.title}
									</Text>
								</ScrollActive>
							</div>

							<div className={'wrapper-content-info'}>
								{pageType !== 'meetings' && (
									<div
										ref={wrapperCategories}
										className={'wrapper-categories flex opacity-0'}>
										{pageType === 'news' && <Text className={'p p3 font-montserrat-arm-medium blue-900 category'}>{pageData.reformCategory.name}</Text>}
										<Text className={'p3 font-montserrat-arm-medium blue-900 category'}>{pageData.category.name}</Text>
										{pageType === 'reforms' && <Text className={'p p3 font-montserrat-arm-medium blue-900 category'}>{translate(pageData.status)}</Text>}
									</div>
								)}
								<div
									ref={shareRef}
									className={'top-share wrapper-share-content opacity-0'}>
									<Text className={'p1 font-montserrat-arm-medium blue-1000 share-title'}>{translate('shareThePage')}:</Text>
									<div className={'wrapper-share-icons'}>
										<Share />
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='col-8 col-t-12'>
						{pageType === 'news' && (
							<Image
								ref={imageRef}
								className={'top-img-wrapper opacity-0'}
								src={pageData.banner_image?.src}
								alt={pageData.banner_image?.alt}
							/>
						)}
						{pageType !== 'news' && (
							<Image
								ref={imageRef}
								className={'top-img-wrapper opacity-0'}
								src={pageData.image?.src}
								alt={pageData.image?.alt}
							/>
						)}
					</div>

					<div
						ref={shareBottomRef}
						className='col-12 bottom-share opacity-0'>
						<div className={'wrapper-share-content'}>
							<Text className={'p1 font-montserrat-arm-medium blue-1000'}>{translate('shareThePage')}:</Text>
							<div className={'wrapper-share-icons'}>
								<Share />
							</div>
						</div>
					</div>
				</div>

				<div
					ref={wrapperBottom}
					className='wrapper-bottom row'>
					<div
						className='col-4 col-m-12'
						ref={wrapperLeft}>
						<div
							ref={stickyWrapperRef}
							className='wrapper-related-sticky'>
							{pageType === 'news' ? (
								<Related
									currentPostId={pageData.id}
									postCategoryId={pageData.category.id}
									reformCategoryId={pageData.reformCategory.id}
								/>
							) : (
								<Tab
									availableTabs={availableTabs}
									onTabSelect={handleTabSelect}
									defaultTabKey={selectedTab}
								/>
							)}
						</div>
					</div>

					<div className='col-8 col-m-12'>
						{pageType === 'news' ? (
							<Content
								image={pageData.image}
								description={pageData.description}
								description_2={pageData.description_2}
							/>
						) : (
							<TabContent
								selectedTab={selectedTab}
								content={pageData}
							/>
						)}
					</div>
				</div>
			</Container>
		</IndividualTemplateStyle>
	);
};

export default withUIContext(withLanguageContext(IndividualTemplate, ['translate']), ['winWidth']);
