import styled from 'styled-components';

const EditorStyle = styled.div`
	white-space: pre-wrap;

	font-size: var(--p4);
	font-family: var(--montserrat_arm);
	font-weight: 400;
	line-height: var(--lineHeightL);
	color: var(--blue1000);

	h5 {
		font-size: var(--h4);
		font-family: var(--montserrat_arm);
		font-weight: 500;
		color: var(--blue1000);
		line-height: var(--lineHeightL);
	}

	h6 {
		font-size: var(--h5);
		font-family: var(--montserrat_arm);
		font-weight: 500;
		color: var(--blue1000);
		line-height: var(--lineHeightL);
	}

	p {
		font-size: var(--p2);
		font-family: var(--montserrat_arm);
		font-weight: 400;
		line-height: var(--lineHeightL);
		color: var(--blue900);
	}

	a {
		font-size: var(--p4);
		font-family: var(--montserrat_arm);
		font-weight: 400;
		line-height: var(--lineHeightL);
		color: var(--blue1000);
		text-decoration: underline;

		@media (hover: hover) {
			&:hover {
				text-decoration: none;
			}
		}
	}

	strong {
		font-weight: 700;
	}

	ol {
		list-style-position: inside;

		li {
			font-size: var(--p4);
			font-family: var(--montserrat_arm);
			font-weight: 400;
			line-height: var(--lineHeightL);
			color: var(--blue1000);
		}
	}

	ul {
		list-style: disc;
		list-style-position: inside;

		li {
			font-size: var(--p4);
			font-family: var(--montserrat_arm);
			font-weight: 400;
			line-height: var(--lineHeightL);
			color: var(--blue1000);
		}
	}

	blockquote {
		border-left: 3px solid var(--blue1000);
		padding-left: 2vw;
		font-size: var(--p4);
		font-family: var(--montserrat_arm);
		font-weight: 400;
		line-height: var(--lineHeightL);
		color: var(--blue1000Color);
	}
`;

export default EditorStyle;
