import { useEffect, useState } from 'react';

//* HOC's
import { withLanguageContext } from '@/context';

//* Libs
import { getRelatedPosts } from '@/libs/get-data';

//* Style
import RelatedStyle from './style';

//* Helpers
import { config } from '@/helpers';

//* Components
import Text from '@/components/Text';
import CustomLink from '@/components/CustomLink';

const Related = ({ currentPostId, postCategoryId, reformCategoryId, selectedLang }) => {
	//! State
	const [data, setData] = useState([]);

	useEffect(() => {
		getRelatedPosts({ currentPostId, postCategoryId, reformCategoryId, lang: selectedLang }).then((res) => setData(res));
	}, [selectedLang, currentPostId, postCategoryId, reformCategoryId]);

	return data?.length > 0 ? (
		<RelatedStyle>
			<div className={'related-block'}>
				<div className={'related-top-block'}>
					<Text
						className={'h5 uppercase font-montserrat-arm-medium blue-1000'}
						text={'relatedArticles'}
					/>
				</div>

				{data.length > 0 ? (
					<div className={'second-block'}>
						{data?.map((post, index) => {
							return (
								<CustomLink
									key={post.id}
									className={'related-block'}
									url={`${config.routes.news.path}/${post.slug}`}>
									<Text className={'text-wrapper p1 font-montserrat-arm-medium blue-1000'}>{post.title}</Text>

									<Text className={'date-wrapper p3 font-montserrat-arm-medium blue-900'}>{post.date}</Text>

									<div className={'border-wrapper'}></div>
								</CustomLink>
							);
						})}
					</div>
				) : null}
			</div>
		</RelatedStyle>
	) : null;
};

export default withLanguageContext(Related, ['selectedLang']);
