'use client';

import { useMemo } from 'react';
import { usePathname } from 'next/navigation';

//* HOC's
import { withLanguageContext } from '@/context';

//* Style
import ShareStyle from './style';

//* Components
import Text from '@/components/Text';
import Icon from '@/components/Icon';
import CustomLink from '../CustomLink';

const Share = ({ className, translate, notShowTitle }) => {
	//! Next Navigation
	const pathname = usePathname();

	const url = useMemo(() => process.env.NEXT_PUBLIC_URL + pathname, [pathname]);

	const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
	const telegramUrl = `https://t.me/share/url?url=${url}`;
	const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;

	return (
		<ShareStyle className={className}>
			<CustomLink
				external
				url={facebookUrl}
				className={'fb-icon'}>
				<Icon
					className={'h6 media-share'}
					name={'fb'}
				/>
			</CustomLink>

			<CustomLink
				external
				url={telegramUrl}
				className={'teleg-icon'}>
				<Icon
					className={'h6 media-share'}
					name={'telegram'}
				/>
			</CustomLink>

			<CustomLink
				external
				url={linkedinUrl}
				className={'linkedin-icon'}>
				<Icon
					className={'h6 media-share'}
					name={'linkedin'}
				/>
			</CustomLink>
		</ShareStyle>
	);
};

export default withLanguageContext(Share, ['translate']);
