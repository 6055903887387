import { useCallback } from 'react';

//* HOC's
import { withLanguageContext } from '@/context';

//* Lottie
import download from '@/lottieFiles/download.json';

//* Components
import Editor from '@/components/Editor';
import Icon from '@/components/Icon';
import Image from '@/components/Image';
import CustomLink from '@/components/CustomLink';
import Button from '@/components/Button';
import Text from '@/components/Text';

const TabContent = ({ selectedTab, content, translate }) => {
	//! Download function
	const handleDownload = useCallback((id) => {
		const link = `${process.env.NEXT_PUBLIC_APP_API_SERVER}/download-file/${id}`;

		window.location.href = link;
	}, []);

	switch (selectedTab) {
		case 'about':
			return (
				<div className='individual-right-wrapper'>
					<div className='about-content'>
						<Editor data={content.about_description} />
					</div>

					<div className='checkout-collapse'>
						<CustomLink
							external
							url={`${content.link}`}
							className={'font-montserrat-arm-medium p3 checkout-link'}>
							{translate('more_details')}
						</CustomLink>

						<Icon
							className='arrow-icon'
							name='arrow'
						/>
					</div>
				</div>
			);
		case 'video':
			return (
				<div className='individual-right-wrapper'>
					{content.video.thumbnail && (
						<Image
							className={'img-wrapper-tab'}
							src={content.video.thumbnail?.src}
							alt={content.video.thumbnail?.alt}
						/>
					)}

					<div className='tab-content-bottom'>
						{content.video.youtube_link && (
							<div className='youtube-collapse'>
								<Icon
									className='youtube-icon h5'
									name='youtube'
								/>

								<CustomLink
									external
									url={content.video.youtube_link}
									className='font-montserrat-arm-medium p3 video-link'>
									{translate('to_see_on_youtube')}
								</CustomLink>
							</div>
						)}

						{content.video.file && (
							<Button
								btnType={'lottie'}
								lottieFiles={download}
								lottieName={'download'}
								className={'font-montserrat-arm-medium collapse-download-btn'}
								onClick={() => handleDownload(content.video.file.id)}>
								PDF
							</Button>
						)}
					</div>
				</div>
			);
		case 'problem':
			return (
				<div className='individual-right-wrapper'>
					<Editor
						className='problem-content'
						data={content.problem_description}
					/>
				</div>
			);
		case 'change':
			return (
				<div className='individual-right-wrapper'>
					<Editor
						className='change-content'
						data={content.change_description}
					/>
				</div>
			);
		case 'mapped':
			return (
				<div className='individual-right-wrapper'>
					<Editor
						className='mapped-content'
						data={content.mapped_description}
					/>
				</div>
			);
		case 'agenda':
			return (
				<div className='individual-right-wrapper'>
					{content.agenda?.title && <Text className='h5 font-montserrat-arm-medium blue-1000 agenda-title'>{content.agenda.title}</Text>}
					<ul>
						{content.agenda?.items?.map((item, index) => (
							<li
								key={index}
								className='agenda-item'>
								<Text className='p2 font-montserrat-arm-regular agenda-item-title'>{item.title}</Text>
								<Text className='font-montserrat-arm-regular blue900 agenda-item-description'>{item.description}</Text>
							</li>
						))}
					</ul>

					<div className='tab-content-bottom'>
						{content.agenda.file && (
							<Button
								btnType={'lottie'}
								lottieFiles={download}
								lottieName={'download'}
								className={'font-montserrat-arm-medium collapse-download-btn'}
								onClick={() => handleDownload(content.agenda.file.id)}>
								{translate('learn_more')}
							</Button>
						)}

						<div className='checkout-collapse'>
							<CustomLink
								external
								url={`${content.link}`}
								className={'font-montserrat-arm-medium p3 checkout-link'}>
								{translate('more_details')}
							</CustomLink>

							<Icon
								className='arrow-icon'
								name='arrow'
							/>
						</div>
					</div>
				</div>
			);
		case 'report':
			return (
				<div className='individual-right-wrapper'>
					{content.report.description && (
						<Editor
							className='report-content'
							data={content.report.description}
						/>
					)}

					{content.report.file && (
						<div className='tab-content-bottom'>
							<Button
								btnType={'lottie'}
								lottieFiles={download}
								lottieName={'download'}
								className={'font-montserrat-arm-medium collapse-download-btn'}
								onClick={() => handleDownload(content.report.file.id)}>
								{translate('PDF')}
							</Button>
						</div>
					)}
				</div>
			);
		case 'minutes':
			return (
				<div className='individual-right-wrapper'>
					{content.minutes.description && (
						<Editor
							className='minutes-content'
							data={content.minutes.description}
						/>
					)}

					<div className='tab-content-bottom'>
						{content.minutes.file && (
							<Button
								btnType={'lottie'}
								lottieFiles={download}
								lottieName={'download'}
								className={'font-montserrat-arm-medium collapse-download-btn'}
								onClick={() => handleDownload(content.minutes.file.id)}>
								{translate('PDF')}
							</Button>
						)}
					</div>
				</div>
			);
		default:
			return null;
	}
};

export default withLanguageContext(TabContent, ['selectedLang', 'translate']);
