//* HOO'C
import { withLanguageContext } from '@/context';

//* Components
import Icon from '../Icon';
import CustomLink from '../CustomLink';
import Text from '../Text';

//* Style
import BreadCrumpStyle from './style';

const BreadCrump = ({ url, text, translate }) => {
	return (
		<BreadCrumpStyle className={''}>
			<CustomLink
				className='p5 font-montserrat-arm-medium'
				url={url}>
				<Icon
					className='arrow-icon-left'
					name='arrow'
				/>
				{translate('backTo')} 
				<Text className='text p5 font-montserrat-arm-medium'> {text} </Text>
			</CustomLink>
		</BreadCrumpStyle>
	);
};

export default withLanguageContext(BreadCrump, ['translate', 'selectedLang']);
