import styled from 'styled-components';

const IndividualTemplateStyle = styled.section`
	--topImgBorderRadius: var(--sp5x);
	--topImgSize: 63.3%;
	--marginT: var(--sp4x);
	--wrapperMiddlePadT: var(--sp15x);
	--wrapperTopPadT: var(--sp14x);
	--wrapperBottomPadT: var(--sp15x);
	--wrapperContentInfoPadB: var(--sp4x);
	--buttonPadTB: var(--sp1-5x);
	--buttonPadLR: var(--sp4x);
	--buttonBorderRadius: var(--sp4x);
	--categoriesGap: var(--sp2x);
	--titleMarginTB: var(--sp6x);
	--imgBorderRadius: var(--sp5x);
	--imgSize: 50.9%;
	--checkoutMarginT: var(--sp6x);
	--agendaTitleMarginB: var(--sp4x);
	--agendaItemDescriptionMarginT: var(--sp2x);
	--agendaItemDescriptionMarginB: var(--sp3x);
	--agendaDescriptionSize: var(--p3);

	margin-top: var(--marginT);

	.wrapper-middle {
		padding-top: var(--wrapperMiddlePadT);
	}

	.wrapper-bottom {
		padding-top: var(--wrapperBottomPadT);
	}

	.wrapper-top-content,
	.wrapper-share-content {
		display: flex;
		justify-content: space-between;
	}

	.wrapper-share-content {
		align-items: center;
	}

	.bottom-share {
		display: none;
	}

	.wrapper-top-content {
		height: 100%;
		flex-direction: column;
	}

	.wrapper-content-info {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.title-wrapper {
		margin: var(--titleMarginTB) 0;
	}

	.wrapper-categories {
		gap: var(--categoriesGap);

		.category {
			padding: var(--buttonPadTB) var(--buttonPadLR);
			border: 2px solid var(--blue900);
			border-radius: var(--buttonBorderRadius);
			text-transform: capitalize;
			transition: all var(--trTime) ease-out;
		}
	}

	.top-img-wrapper {
		--proportion: var(--topImgSize);
		img {
			border-radius: var(--topImgBorderRadius);
		}
	}

	.wrapper-related-sticky {
		will-change: transform;
	}

	.date-text {
		color: var(--lightBlue);
	}

	.agenda-title {
		margin-bottom: var(--agendaTitleMarginB);
	}

	.agenda-item-description {
		margin: var(--agendaItemDescriptionMarginT) 0 var(--agendaItemDescriptionMarginB);
		font-size: var(--agendaDescriptionSize);
	}

	.tab-content-bottom,
	.checkout-collapse,
	.youtube-collapse {
		display: flex;
		align-items: center;
	}

	.tab-content-bottom {
		justify-content: space-between;
	}

	.checkout-collapse,
	.tab-content-bottom {
		margin-top: var(--checkoutMarginT);
	}

	.agenda-item-title,
	.checkout-link,
	.arrow-icon,
	.video-link,
	.youtube-icon {
		color: var(--lightBlue);
	}

	.arrow-icon {
		margin-left: var(--sp1x);
		font-size: var(--p1);
		transform: rotate(270deg);
		display: inline-flex;
	}

	.img-wrapper-tab {
		--proportion: var(--imgSize);

		img {
			border-radius: var(--imgBorderRadius);
		}
	}

	.agenda-item:last-child > *:last-child {
		margin-bottom: unset;
	}

	//! 1920 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) {
		--topImgBorderRadius: var(--sp4x);
		--marginT: var(--sp2x);
		--wrapperMiddlePadT: var(--sp10x);
		--wrapperTopPadT: var(--sp15x);
		--buttonPadLR: var(--sp3x);
		--buttonBorderRadius: var(--sp3x);
		--titleMarginTB: var(--sp4x);
		--imgBorderRadius: var(--sp2-5x);
		--checkoutMarginT: var(--sp5x);
		--agendaTitleMarginB: var(--sp3x);
		--agendaItemDescriptionMarginT: var(--sp1x);
	}

	//! 1536 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) {
		--topImgBorderRadius: var(--sp3x);
		--marginT: var(--sp3x);
		--wrapperMiddlePadT: var(--sp8x);
		--categoriesGap: var(--sp1-5x);
		--buttonPadLR: var(--sp2x);
		--imgBorderRadius: var(--sp3x);
		--wrapperBottomPadT: var(--sp10x);
		--checkoutMarginT: var(--sp4x);
	}

	//! 1366 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) {
		--topImgBorderRadius: var(--sp2-5x);
		--marginT: var(--sp2x);
		--wrapperTopPadT: var(--sp9x);
		--titleMarginTB: var(--sp3-5x);
		--borderRadius: var(--sp2-5x);
		--imgBorderRadius: var(--sp2-5x);
		--agendaItemDescriptionMarginB: var(--sp2x);
	}

	//! 1280 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) {
		--topImgBorderRadius: var(--sp2x);
		--wrapperMiddlePadT: var(--sp5x);
		--wrapperBottomPadT: var(--sp8x);
		--wrapperTopPadT: var(--sp6x);
		--titleMarginTB: var(--sp3x);
		--borderRadius: var(--sp2-5x);
	}

	//! 768 */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) {
		--topImgBorderRadius: var(--sp1-5x);
		--marginT: var(--sp1x);
		--categoriesGap: var(--sp1x);
		--wrapperTopPadT: 0;
		--borderRadius: var(--sp1-5x);
		--imgBorderRadius: var(--sp2x);
		--imgSize: 63.9%;
		--wrapperBottomPadT: var(--sp4x);
		--checkoutMarginT: var(--sp3x);
		--agendaDescriptionSize: var(--p1);

		.wrapper-content-info {
			margin-bottom: var(--wrapperContentInfoPadB);
		}

		.share-title {
			display: none;
		}

		.wrapper-content-info {
			flex-direction: row;
		}
	}

	//! Mobile */
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--marginT: var(--sp1-5x);
		--borderRadius: var(--sp2x);
		--imgBorderRadius: var(--sp5x);
		--imgBorderRadius: var(--sp1-5x);
		--imgSize: 51.4%;
		--wrapperContentInfoPadB: var(--sp5x);
		--agendaDescriptionSize: var(--p1);

		.bottom-share {
			display: flex;
			padding-top: var(--sp4x);
			.wrapper-share-content {
				justify-content: space-between;
				width: 100%;
			}
		}

		.top-share {
			display: none;
		}

		.share-title {
			display: block;
		}

		.wrapper-related-sticky,
		.individual-right-wrapper {
			padding-top: var(--sp4x);
		}

		.wrapper-related-sticky {
			max-width: 100%;
			width: 100%;
		}
	}
`;

export default IndividualTemplateStyle;
