import styled from 'styled-components';

const BreadCrumpStyle = styled.div`
	--iconMarginR: var(--sp1x);

	.link-item {
		display: flex;
		align-items: center;
		color: var(--blue900);
		opacity: 50%;
	}

	.text {
		color: var(--blue900);
		&::first-letter {
			text-transform: lowercase;
		}
	}

	.arrow-icon-left {
		transform: rotate(90deg);
		margin-right: var(--iconMarginR);
		font-size: var(--h6);
	}
`;

export default BreadCrumpStyle;
